import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';

// material-ui
import { Button, Link, Stack } from '@material-ui/core';

// project imports
import AuthCardWrapper from './AuthCardWrapper';
import AuthWrapper from './AuthWrapper';
import { RootState } from '../../store/store';
import { SET_SUB_RENEW_DATA } from '../../store/account/actions';
import { useAppDispatch } from '../../hooks/redux';
import AnimateButton from 'ui-component/extended/AnimateButton';

const SubRequired = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { stripe_url, required_subscription } = useSelector((AppState: RootState) => AppState.account);

    const onCancel = useCallback(() => {
        dispatch({ type: SET_SUB_RENEW_DATA, payload: { stripe_url: null, required_subscription: null } });
        navigate('/login');
    }, [dispatch, navigate]);

    if (!stripe_url || !required_subscription) {
        return <Navigate to="/login" />;
    }
    return (
        <AuthWrapper>
            <AuthCardWrapper cardSubheaderText="Subscription activation required">
                <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                    <AnimateButton>
                        <Button variant="outlined" onClick={onCancel}>
                            Cancel
                        </Button>
                    </AnimateButton>
                    <AnimateButton>
                        <Link href={stripe_url}>
                            <Button variant="contained" color="secondary">
                                Purchase
                            </Button>
                        </Link>
                    </AnimateButton>
                </Stack>
            </AuthCardWrapper>
        </AuthWrapper>
    );
};

export default SubRequired;
