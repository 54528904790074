import MinimalLayout from '../layout/MinimalLayout';
import Loadable from '../ui-component/Loadable';
import { lazy } from 'react';
import GuestGuard from '../utils/route-guard/GuestGuard';
import NavMotion from '../layout/NavMotion';
import Head from '../utils/head';
import ThankYouPage from '../views/authentication/ThankYouPage';
import Register from '../views/authentication/Register';
import SubRequired from '../views/authentication/SubRequired';
import ErrorCatcher from '../ui-component/error-catcher';

const ForgotPassword = Loadable(lazy(() => import('../views/authentication/ForgotPassword')));
const ResetPassword = Loadable(lazy(() => import('../views/authentication/ResetPassword')));
const Login = Loadable(lazy(() => import('../views/authentication/Login')));
const CheckMail = Loadable(lazy(() => import('../views/authentication/CheckMail')));
const AcceptInviteByEmail = Loadable(lazy(() => import('../views/accept-invite/AcceptInviteByEmail')));
const AcceptInviteByPhone = Loadable(lazy(() => import('../views/accept-invite/AcceptInviteByPhone')));
const OuterAppointment = Loadable(lazy(() => import('views/calendar/components/OuterAppointment')));
const CustomerAuthScreen = Loadable(lazy(() => import('views/customer-pages/auth')));

const AuthenticationRoutes = [
    {
        path: 'login',
        element: (
            <>
                <Head title="Log In |" />
                <MinimalLayout />
            </>
        ),
        children: [
            {
                path: '/login/',
                element: (
                    <NavMotion>
                        <GuestGuard>
                            <ErrorCatcher wrappedComponentName="Login">
                                <Login />
                            </ErrorCatcher>
                        </GuestGuard>
                    </NavMotion>
                )
            },
            {
                path: '/login/required-subscription',
                element: (
                    <NavMotion>
                        <GuestGuard>
                            <SubRequired />
                        </GuestGuard>
                    </NavMotion>
                )
            }
        ]
    },
    {
        path: 'register',
        element: (
            <>
                <Head title="Register |" />
                <MinimalLayout />
            </>
        ),
        children: [
            {
                path: '/register',
                element: (
                    <NavMotion>
                        <GuestGuard>
                            <ErrorCatcher wrappedComponentName="Register">
                                <Register />
                            </ErrorCatcher>
                        </GuestGuard>
                    </NavMotion>
                )
            }
        ]
    },
    {
        path: 'register-trial',
        element: (
            <>
                <Head title="Trial Registration |" metaTags={[{ name: 'robots', value: 'noindex' }]} />
                <MinimalLayout />
            </>
        ),
        children: [
            {
                path: '/register-trial',
                element: (
                    <NavMotion>
                        <GuestGuard>
                            <ErrorCatcher wrappedComponentName="Register (with trial)">
                                <Register withTrial />
                            </ErrorCatcher>
                        </GuestGuard>
                    </NavMotion>
                )
            }
        ]
    },
    {
        path: 'subscribe-success',
        element: (
            <>
                <Head title="Thanks for your order |" />
                <MinimalLayout />
            </>
        ),
        children: [
            {
                path: '/subscribe-success',
                element: (
                    <NavMotion>
                        <GuestGuard>
                            <ErrorCatcher wrappedComponentName="ThankYouPage">
                                <ThankYouPage />
                            </ErrorCatcher>
                        </GuestGuard>
                    </NavMotion>
                )
            }
        ]
    },
    {
        path: 'forgot-password',
        element: (
            <>
                <Head title="Can't log in? |" />
                <MinimalLayout />
            </>
        ),
        children: [
            {
                path: '/forgot-password',
                element: (
                    <NavMotion>
                        <GuestGuard>
                            <ErrorCatcher wrappedComponentName="ForgotPassword">
                                <ForgotPassword />
                            </ErrorCatcher>
                        </GuestGuard>
                    </NavMotion>
                )
            }
        ]
    },
    {
        path: 'reset-password',
        element: <MinimalLayout />,
        children: [
            {
                path: '/reset-password',
                element: (
                    <NavMotion>
                        <GuestGuard>
                            <ErrorCatcher wrappedComponentName="ResetPassword">
                                <ResetPassword />
                            </ErrorCatcher>
                        </GuestGuard>
                    </NavMotion>
                )
            }
        ]
    },
    {
        path: 'check-mail',
        element: <MinimalLayout />,
        children: [
            {
                path: '/check-mail',
                element: (
                    <NavMotion>
                        <GuestGuard>
                            <ErrorCatcher wrappedComponentName="CheckMail">
                                <CheckMail />
                            </ErrorCatcher>
                        </GuestGuard>
                    </NavMotion>
                )
            }
        ]
    },
    {
        path: '/invite/:companyId/:email/',
        element: (
            <ErrorCatcher wrappedComponentName="AcceptInviteByEmail">
                <AcceptInviteByEmail />
            </ErrorCatcher>
        )
    },
    {
        path: '/invite/:companyId/:email/:token',
        element: (
            <ErrorCatcher wrappedComponentName="AcceptInviteByEmail">
                <AcceptInviteByEmail />
            </ErrorCatcher>
        )
    },
    {
        path: '/accept-sms-invite/:encryptedToken',
        element: (
            <ErrorCatcher wrappedComponentName="AcceptInviteByPhone">
                <AcceptInviteByPhone />
            </ErrorCatcher>
        )
    },
    {
        path: '/calendar/organization/:companyId/appointment/:eventId/email/:email',
        element: (
            <ErrorCatcher wrappedComponentName="OuterAppointment">
                <OuterAppointment />
            </ErrorCatcher>
        )
    },
    {
        path: '/calendar/organization/:companyId/appointment/:eventId/',
        element: (
            <ErrorCatcher wrappedComponentName="OuterAppointment">
                <OuterAppointment />
            </ErrorCatcher>
        )
    },
    {
        path: '/customer-auth/:companySlug',
        element: (
            <>
                <Head title="Log In |" />
                <CustomerAuthScreen />
            </>
        )
    }
];

export default AuthenticationRoutes;
