import { useAppSelector } from './redux';
import { useMediaQuery } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { useMemo } from 'react';
import useAppBreadcrumbs from './use-app-breadcrumbs';
import useImpersonate from './use-impersonate';

const useAppHeaderHeight = () => {
    const matchSm = useMediaQuery((themeParam: Theme) => themeParam.breakpoints.down('sm'));
    const { showBreadcrumbs } = useAppBreadcrumbs();
    const { isImpersonatedSession } = useImpersonate();
    const { headerHeight } = useAppSelector((state) => state.layout);

    return useMemo(() => {
        const breadcrumbsHeight = showBreadcrumbs ? 60 : 0;
        const impersonateAlertHeight = isImpersonatedSession ? 49 : 0;
        const baseHeaderHeight = (matchSm ? 60 : 80) + breadcrumbsHeight + impersonateAlertHeight;

        return `${headerHeight || baseHeaderHeight}px`;
    }, [headerHeight, matchSm, showBreadcrumbs, isImpersonatedSession]);
};

export default useAppHeaderHeight;
