import useAuth from '../../../../hooks/useAuth';
import EmployeeRoleChip from '../../../../ui-component/EmployeeRoleChip';
import { Box, Divider, Link, ListItemButton, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import { NavLink } from 'react-router-dom';
import SidebarOrganizationSwitcher from '../../SidebarOrganizationSwitcher';
import { IconCurrencyDollar } from '@tabler/icons-react';
import QrCodeOutlinedIcon from '@mui/icons-material/QrCodeOutlined';
import LocationSwitcher from '../../LocationSwitcher';
import useTimeTracker from '../../../../hooks/use-time-tracker';
import Timer from '../../../../ui-component/time-tracker/Timer';

import StartTimeTrackerIcon from '@mui/icons-material/PlayCircleOutline';
import StopTimeTrackerIcon from '@mui/icons-material/StopCircleOutlined';

const itemSx = {
    color: 'grey.800',
    p: 1.5,
    borderRadius: '8px',
    '&:hover, &.active': { color: 'primary.900', backgroundColor: 'primary.light' }
};

const UserDetails = () => {
    const { user } = useAuth();
    const { isTimeTrackerExtensionEnabled, isTimeTrackerStarted, onToggleTimeTracker, getInitialTime, loading } = useTimeTracker();

    return user ? (
        <>
            <Stack spacing={1} pt={3} pb={1} sx={{ color: 'grey.800' }} alignItems="flex-start">
                <EmployeeRoleChip role={user.employee.role.name} size="medium" sx={{ borderRadius: '16px' }} />
                <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between" sx={{ width: '100%' }}>
                    <Typography variant="h4" sx={{ fontWeight: 600, fontSize: '1rem', lineHeight: '1.5rem', color: 'inherit' }}>
                        {`Hi, ${user?.title}`}
                    </Typography>
                    <SidebarOrganizationSwitcher />
                </Stack>
                <LocationSwitcher fullWidth />
            </Stack>
            <Divider sx={{ my: 1 }} />
            <Box>
                {isTimeTrackerExtensionEnabled && (
                    <ListItemButton sx={itemSx} onClick={onToggleTimeTracker} disabled={loading}>
                        <ListItemIcon sx={{ color: 'inherit !important' }}>
                            {!isTimeTrackerStarted && <StartTimeTrackerIcon sx={{ color: 'inherit' }} />}
                            {isTimeTrackerStarted && <StopTimeTrackerIcon sx={{ color: 'inherit' }} />}
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <Typography sx={{ color: 'inherit', lineHeight: '20px' }}>
                                    {!isTimeTrackerStarted && 'Start Time Tracking'}
                                    {isTimeTrackerStarted && <Timer initialTime={getInitialTime} />}
                                </Typography>
                            }
                        />
                    </ListItemButton>
                )}

                <ListItemButton component={NavLink} to="/profile" sx={itemSx}>
                    <ListItemIcon sx={{ color: 'inherit !important' }}>
                        <ManageAccountsOutlinedIcon sx={{ color: 'inherit' }} />
                    </ListItemIcon>
                    <ListItemText primary={<Typography sx={{ color: 'inherit', lineHeight: '20px' }}>Profile Settings</Typography>} />
                </ListItemButton>
                <ListItemButton component={NavLink} to="/profile-card-link" sx={itemSx}>
                    <ListItemIcon sx={{ color: 'inherit !important' }}>
                        <QrCodeOutlinedIcon sx={{ color: 'inherit' }} />
                    </ListItemIcon>
                    <ListItemText primary={<Typography sx={{ color: 'inherit', lineHeight: '20px' }}>Show Profile Card</Typography>} />
                </ListItemButton>
                {user.stripe_billing_portal_url && (
                    <Link href={user.stripe_billing_portal_url} target="_blank" underline="none">
                        <ListItemButton sx={itemSx}>
                            <ListItemIcon sx={{ color: 'inherit !important' }}>
                                <IconCurrencyDollar stroke={1.5} size="1.3rem" />
                            </ListItemIcon>
                            <ListItemText primary={<Typography sx={{ color: 'inherit', lineHeight: '20px' }}>Billing Portal</Typography>} />
                        </ListItemButton>
                    </Link>
                )}
            </Box>
            <Divider sx={{ my: 1 }} />
        </>
    ) : null;
};

export default UserDetails;
